import { useState } from 'react';
import { useHistory } from 'react-router';
import {
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
} from '../../utils';
import { WaiverField } from '../personal/questions';
import { ButtonsBar, Form, Screen } from '../../components';
import { ROUTE_KEYS } from '../../app.constants';

export const Waiver = () => {
  const [activeOverlay, setActiveOverlay] = useState();
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  const {
    redirect,
    queryString: { fr_id },
  } = useQueryString();
  const {
    data: { waiverOption, transactionId },
    commands: { setWaiverOption },
  } = useRegistrationDataProvider();
  const {
    data: { selectedParticipationType },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();
  const waiver = selectedParticipationType.waiver.content
    .replace(/(&quot;)/g, '"')
    .replace(/(&#174)/g, '®');

  const { goBack } = useHistory();

  const handleFormChange = (fields) => {
    setWaiverOption(fields);
    setIsNextButtonEnabled(
      fields.termsAndConditions && fields.cancellationPolicy
    );
  };

  const handleFormSubmit = () => {
    postStep(transactionId, {
      pg: 'waiver',
      fr_id,
      extra_person: '',
      fr_accept:
        waiverOption.termsAndConditions && waiverOption.cancellationPolicy
          ? 'on'
          : '',
      'fuw_next.x': 'Next Step',
    }).then(() => redirect(`${ROUTE_KEYS.SUMMARY}`));
  };

  return (
    <Screen>
      <Form onChange={handleFormChange} onSubmit={handleFormSubmit}>
        <WaiverField
          waiver={waiver}
          overlayCommands={{ activeOverlay, setActiveOverlay }}
          defaultChecked={waiverOption && waiverOption.waiver}
        />
        <div className='pt-4'>
          <ButtonsBar
            handleSecondary={goBack}
            handlePrimary={handleFormSubmit}
            disabled={!isNextButtonEnabled}
          />
        </div>
      </Form>
    </Screen>
  );
};
