import { isArray, isNil } from 'lodash';
import i18n from 'i18next';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  LANGUAGE_CODES,
  RACE_REQUIREMENTS,
  DEFAULT_DONATION_LEVEL_ID_PROD,
  DEFAULT_DONATION_LEVEL_ID_QA,
} from '../app.constants';
import { decodeQueryString } from './QueryStrings';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
export const formatEventDate = (eventDate, withDay = false) => {
  if (!eventDate) return ''; // Handle empty input
  const parsedDate = dayjs(eventDate);
  return withDay
    ? parsedDate.format('dddd, MMMM D, YYYY')
    : parsedDate.format('MMMM D, YYYY');
};

const DATE_US = {
  LOCALE: i18n.t('screens.personal_info.en_sp_month'),
  FORMAT: 'short',
};

i18n.on('languageChanged', () => {
  DATE_US.LOCALE = i18n.t('screens.personal_info.en_sp_month');
});

/**
 *
 * @param {string, number} value1
 * @param {string, number} value2
 */
export const isEqual = (value1, value2) => value1 === value2;

/**
 * @description use this for if Array or object empty
 * @param {object, Array} obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
  if (!obj) return true;
  if (typeof obj === 'string' && !obj.trim().length) return true;
  if (typeof obj === 'object' && !Object.keys(obj).length) return true;
  return Array.isArray(obj) && !obj.length;
};

/**
 * @description converts a string boolean to boolean value
 * @param {string} s
 * @returns {boolean}
 */
export const stringToBoolean = (s) => s.toLowerCase() === 'true';

/**
 *
 * @param {string} s
 */
export const capitalize = (s) =>
  typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1);

/**
 *
 * @param {string} str
 */
export const camelCase = (str) =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

/**
 *
 * @param {array} data
 */
export const getSelectFieldData = (data = []) =>
  data.map((v) => ({ label: v, value: v }));

/**
 *
 * @param {string} locale
 * @param {string} format
 */
export const getListOfMonths = (
  locale = DATE_US.LOCALE,
  format = DATE_US.FORMAT
) =>
  Array.from({ length: 12 }, (_, index) =>
    new Date(null, index + 1, null).toLocaleDateString(locale, {
      month: format,
    })
  );

/**
 * @default 31 days
 * @description gives list of days
 */
export const getListOfDays = () =>
  Array.from({ length: 31 }, (_, i) => `0${i + 1}`.slice(-2));

/**
 *
 * @param {number} start current year
 * @param {number} stop number that you want to future/past years
 * @param {number} step number to go future/past
 */
export const getListOfYears = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => `${start + i * step}`
  );

/**
 *
 * @param {string} month gives short name for given month
 */
export const getMonthShortName = (month, locale = DATE_US.LOCALE) =>
  new Date(null, month, null).toLocaleDateString(locale, {
    month: DATE_US.FORMAT,
  });

/**
 *
 * @param {string} month name of the month
 * @returns {string} index of a month with prefix 0
 */
export const getIndexForMonth = (
  month,
  locale = DATE_US.LOCALE,
  format = DATE_US.FORMAT
) =>
  String(getListOfMonths(locale, format).indexOf(month) + 1).padStart(2, '0');

/**
 *
 * @param {string} date
 */
export const getDateOfBirth = (date, locale) => {
  const newArray = date.split('-');
  const newMonth = getMonthShortName(newArray[1], locale);
  return { month: newMonth, day: newArray[2], year: newArray[0] };
};

/**
 *
 * @param {string} code
 */
export const getPartTypeFromCode = (code) => {
  if (!code) return {};
  if (code === 'supporter') {
    return {
      attendance: '',
      distance: '',
      commitment: '',
    };
  }
  const partType = code.split('_');
  return {
    attendance: partType[0],
    distance: partType[1],
    commitment: partType[2],
  };
};

/**
 *
 * @param {string} str
 */
export const convertToNumber = (str = '') => Number(str.slice(1));

/**
 * @description gives current year
 * @returns {number}
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * @description This gives us date set for fields like DOB, Expiration Date etc
 */
export const getDateOfBirthOptions = () => {
  const currentYear = getCurrentYear();
  const monthNames = getSelectFieldData(getListOfMonths());
  const days = getSelectFieldData(getListOfDays());
  const years = getSelectFieldData(
    getListOfYears(currentYear, currentYear - 100, -1)
  );
  return { monthNames, days, years };
};

export const getCreditCardExpirationOptions = () => {
  const currentYear = getCurrentYear();
  const years = getSelectFieldData(
    getListOfYears(currentYear, currentYear + 10, 1)
  );
  return { ...getDateOfBirthOptions(), years };
};

/**
 *
 * @param {object} address
 * @returns {object}
 */
export const getAddressObject = (address) => {
  if (isEmpty(address)) return null;
  for (const key of Object.keys(address)) {
    if (isEmpty(address[key])) {
      address[key] = null;
    }
  }
  return address;
};

/**
 * @description This util function is to get the minimum age based on the distance selection
 * @param {string} distanceValue
 * @returns {number}
 */
export const getAgeFromRequirements = (distanceValue) =>
  (
    RACE_REQUIREMENTS.find(({ distance }) =>
      isEqual(distance, distanceValue)
    ) || {}
  ).age ?? 0;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isCountryUSA = (value) => isEqual(value, 'United States');

/**
 * @description Masking Phone Number
 * @param {string} inputValue
 */
export const formatPhoneNumber = (inputValue) => {
  const maskTranslation = inputValue
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !maskTranslation[2]
    ? maskTranslation[1]
    : `(${maskTranslation[1]}) ${maskTranslation[2]}${
        maskTranslation[3] && `-${maskTranslation[3]}`
      }`;
};

/**
 *
 * @param {regex} pattern
 */
export const generateNewRegex = (pattern) => new RegExp(pattern);

/**
 * @description find credit card type
 * @param { string } ccvalue
 */
export const getCreditCardType = (ccvalue) => {
  const amex = generateNewRegex(/^3[47][0-9]{0,14}$/);
  const visa = generateNewRegex(/^4[0-9](?:[0-9]{3})?$/);
  const master = generateNewRegex(/^5[1-5][0-9]$/);
  const discover1 = generateNewRegex(/^6011[0-9]*$/);
  const discover2 = generateNewRegex(/^62[24568][0-9]*$/);
  const discover3 = generateNewRegex(/^6[45][0-9]*$/);
  if (amex.test(ccvalue)) return 'AMEX';
  if (visa.test(ccvalue)) return 'VISA';
  if (master.test(ccvalue)) return 'MASTER';
  if (
    discover1.test(ccvalue) ||
    discover2.test(ccvalue) ||
    discover3.test(ccvalue)
  )
    return 'DISCOVER';
  return ccvalue;
};

export const convertCurrencyToNumber = (currency) =>
  currency.replace(/[$,]+/g, '').toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

/**
 *
 * @param {number | string} value
 * @param { object } options
 * @return cents
 */
export const convertCentsToDollars = (value, options = {}) => {
  let dollars = 0;

  if (value) {
    const cleansedValue = parseFloat(`${value}`.replace(/[^\d.-]/g, ''));
    if (options.noDivider) {
      dollars = cleansedValue;
    } else {
      dollars = cleansedValue / 100;
    }
  }

  if (options.formatted) {
    if (options.rounded) {
      return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
    return dollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return dollars;
};

export const stringReplace = (str = '', replace = '', replaceWith = '') =>
  str.replace(replace, replaceWith);

export const stringReplaceAll = (str = '', replace = '', replaceWith = '') =>
  str.replaceAll(replace, replaceWith);

export const isLocalHost = isEqual(location.hostname, 'localhost');

export const convertToArray = (value) => {
  if (isNil(value)) return undefined;
  if (!isArray(value)) return [value];
  return value;
};

/**
 * @description reference https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 * Sort Collator */
export const sortCollator = new Intl.Collator(navigator.language, {
  caseFirst: 'upper',
  ignorePunctuation: true,
  numeric: true,
  sensitivity: 'base',
});

/**
 *
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const limit = (value = 0, min = 0, max = 0) =>
  Math.max(min, Math.min(max, value));

/**
 *
 * @param {number, string} total
 * @param {number} perPage
 * @returns
 */
export const getNumberOfPages = (total = 0, perPage = 0) =>
  Math.ceil(Number(total) / perPage);

/** company icons  */
export const getCompanyIcon = (name) => {
  if (isEqual(name, 'Patient Family Teams'))
    return { name: 'group-walking', viewBox: '0 0 64 64' };
  if (isEqual(name, 'Community Challenge'))
    return { name: 'community-walking', viewBox: '0 0 64 64' };
  if (isEqual(name, 'Corporate Challenge'))
    return { name: 'become-partner', viewBox: '0 0 64 64' };
  return undefined;
};

/** Company Name Translations */
export const getCompanyName = (name) => {
  if (isEqual(name, 'Patient Family Teams'))
    return i18n.t('companies.patient_family_teams');
  if (isEqual(name, 'Community Challenge'))
    return i18n.t('companies.community_challenge');
  if (isEqual(name, 'Corporate Challenge'))
    return i18n.t('companies.corporate_challenge');
  return name;
};

export const isEnglish = (locale) => isEqual(locale, LANGUAGE_CODES.EN);

export const getParticipationDataObject = (participationLimits, data = []) => {
  let commitments = [];

  const distances = [
    'Marathon',
    'Half Marathon',
    '10K',
    '5K',
    '2-Race Challenge',
    /* '4-Race Challenge', */
  ];
  const {
    attendance: queryAttendance,
    code,
    distance: queryDistance,
    reg_type,
  } = decodeQueryString(location.search);
  const partType = getPartTypeFromCode(code);
  const attendance = queryAttendance || partType.attendance;
  const distance = queryDistance || partType.distance;

  if (isEqual(attendance, 'Virtual')) {
    commitments = ['Bronze', 'Silver', 'Gold'];
  } else {
    commitments = ['Premier', 'Platinum', 'Gold', 'Silver', 'Bronze'];
  }

  // Heroes SellOut
  if (reg_type) {
    const distanceData = distances.map((ds) => {
      const filteredDistanceData = data.filter((dd) =>
        isEqual(dd.distance, ds)
      );
      const totalNumberParticicpants = filteredDistanceData.reduce(
        (acc, crr) => {
          if (crr && crr.participationTypeRegistrationLimit)
            return (
              acc +
              Number(
                crr.participationTypeRegistrationLimit.currentRegistrations
              )
            );
          return 0;
        },
        0
      );
      const distanceLimits = participationLimits.data?.limits.distances.find(
        (pd) => isEqual(pd.name, ds)
      ).limit;
      return {
        distance: ds,
        soldOut: Number(distanceLimits) <= totalNumberParticicpants,
      };
    });

    const participationData = commitments.map((cm) => {
      const filterdCommitmentData = data.filter((pd) =>
        isEqual(pd.commitment, cm)
      );
      const totalNumberParticicpants = filterdCommitmentData.reduce(
        (acc, crr) => {
          if (crr && crr.participationTypeRegistrationLimit)
            return (
              acc +
              Number(
                crr.participationTypeRegistrationLimit.currentRegistrations
              )
            );
          return 0;
        },
        0
      );
      const commitmentLevelLimit = participationLimits.data?.limits.heroes.find(
        (pr) => isEqual(pr.name, cm)
      ).limit;
      const participationType = data.find(
        (p) =>
          isEqual(p.attendance, attendance) &&
          isEqual(
            stringReplaceAll(p.distance, '-', ' '),
            stringReplaceAll(distance, '-', ' ')
          ) &&
          isEqual(p.commitment, cm)
      );
      return {
        ...participationType,
        soldOut: Number(commitmentLevelLimit) <= totalNumberParticicpants,
      };
    });
    return { data: { distanceData, participationData } };
  }

  // General SellOut
  const genDistanceData = distances.map((d) => {
    const distanceType =
      data.find(
        (gd) => isEqual(gd.attendance, attendance) && isEqual(gd.distance, d)
      ) || {};
    const totalNumberParticicpants = [distanceType].reduce((acc, crr) => {
      if (crr && crr.participationTypeRegistrationLimit)
        return (
          acc +
          Number(crr.participationTypeRegistrationLimit.currentRegistrations)
        );
      return 0;
    }, 0);
    const distanceLimits =
      participationLimits.data &&
      participationLimits.data.limits.distances.find((pd) =>
        isEqual(pd.name, d)
      ).limit;
    return {
      ...distanceType,
      soldOut: Number(distanceLimits) <= totalNumberParticicpants,
    };
  });
  return {
    data: {
      distanceData: genDistanceData,
      participationData:
        genDistanceData.filter(
          (gd) =>
            isEqual(gd.attendance, attendance) &&
            isEqual(
              stringReplaceAll(gd.distance, '-', ' '),
              stringReplaceAll(distance, '-', ' ')
            )
        ) || [],
    },
  };
};

export const trimZeroDecimals = (value) => value.replace('.00', '');

export const isQA = window.location.hostname === 'fundraising.qa.stjude.org';
export const isPROD = window.location.hostname === 'fundraising.stjude.org';
export const isLocal = window.location.hostname === 'localhost';

export const getDonationUrl = (baseDonationUrl) => {
  if (!baseDonationUrl.includes('DonationLevel')) {
    const donationLevel = isPROD
      ? DEFAULT_DONATION_LEVEL_ID_PROD
      : DEFAULT_DONATION_LEVEL_ID_QA;
    return `${baseDonationUrl}&set.DonationLevel=${donationLevel}`;
  }
  return baseDonationUrl;
};

export const sortCommitmentLevels = (participationData) => {
  const commitmentOrder = ['Premier', 'Platinum', 'Gold', 'Silver', 'Bronze'];
  return [...participationData].sort(
    (a, b) =>
      commitmentOrder.indexOf(a.commitment) -
      commitmentOrder.indexOf(b.commitment)
  );
};
