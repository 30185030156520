import React from 'react';
import { useRegistrationDataProvider } from '../../AppDataContext';
import { useConfiguration } from '../../configuration/ConfigurationContext';
import { useQueryString } from '../../QueryStrings';
import { useAuth } from '../../security/SecurityContext';
import { isEqual } from '../../utils';
import { useRegTotalToPay } from './PaymentHooks';
import { LUMINATE_API_SECTIONS, ROUTE_KEYS } from '../../../app.constants';
import { useQuery } from '../QueryHooks';

export const getCheckoutConfig = (checkoutOptions) => {
  if (!checkoutOptions) throw new Error('Checkout options are required');
  const digitalWalletEnabled = checkoutOptions.paymentTypes.digitalWallet;
  return {
    zone: checkoutOptions?.pcoEnvironmentZone,
    environmentId: checkoutOptions?.environmentId,
    paymentConfigurationId: checkoutOptions?.merchantAccountConfigurationId,
    applicationName: checkoutOptions?.applicationName,
    feeOffsetType: 0,
    languageLocale: checkoutOptions?.languageLocale,
    primaryColor: checkoutOptions?.primaryColor,
    workflowMode: 'modal',
    paymentMethodOptions: {
      directDebit: {
        enabled: checkoutOptions.paymentTypes.debit,
      },
      card: {
        enabled: checkoutOptions.paymentTypes.credit,
      },
      payPal: {
        enabled: checkoutOptions.paymentTypes.paypal,
      },
      wallets: {
        applePayEnabled: digitalWalletEnabled,
        googlePayEnabled: digitalWalletEnabled,
      },
    },
  };
};

export const getCheckoutInstance = (config) => window.BlackbaudCheckout(config);

export const getCountryCode = (countryCodeNamePairs, countryName) => {
  const countryCodeNames = JSON.parse(countryCodeNamePairs);
  const country = Object.entries(countryCodeNames).find(
    ([, name]) => name === countryName
  );
  return country && country[0];
};

const fetcherReg = async (url, stepData) => {
  const body = new URLSearchParams();

  for (const key of Object.getOwnPropertyNames(stepData)) {
    body.append(key, stepData[key]);
  }

  const options = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
      'Accept-Language': 'en-US,en;q=0.5',
      'Upgrade-Insecure-Requests': '1',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    mode: 'cors',
    method: 'POST',
    body,
  };

  return await fetch(url, options);
};

export const useSubmitRegistration = () => {
  const [refreshToggle, setRefreshToggle] = React.useState(false);
  const { settings } = useConfiguration();
  const {
    queryString: { code, fr_id },
  } = useQueryString();
  const {
    commands: { resetStorage },
  } = useRegistrationDataProvider();

  const refresh = () => setRefreshToggle((prevState) => !prevState);

  const startTransaction = (team = '', stepData = {}) => {
    const teamOptionValue = isEqual(team, 'individual')
      ? 'fr_tm_opt=none'
      : isEqual(team, 'create-team')
        ? 'fr_tm_opt=new'
        : '';

    return fetcherReg(
      `${settings.luminate.url}/TRR/Heroes/Heroes?${teamOptionValue}&pg=tfind&fr_id=${fr_id}&s_promoCode=${code}&skip_login_page=true`,
      stepData
    );
  };

  const postStep = async (transactionId, stepData = {}) =>
    await fetcherReg(
      `${settings.luminate.url}/TRR/${transactionId}`,
      stepData
    ).then((res) => {
      /** TODO: this conditation is redudant. We need to wait until
       * figure out the best way for thank you page on login */
      if (res) {
        const pgValue = new URL(res.url).searchParams.get('pg');
        if (isEqual('rthanks', pgValue)) {
          resetStorage();
          return (window.location.href = `${res.url}`);
        }
        return res.text();
      }
    });

  return { postStep, refresh, refreshToggle, startTransaction };
};

export const useJoinTeam = () => {
  const {
    commands: { setTransactionId, setJoinTeamId },
  } = useRegistrationDataProvider();
  const { startTransaction, postStep } = useSubmitRegistration();
  const { redirect } = useQueryString();

  const joinTeamFromExternalLink = async (team, teamId, fr_id, pagename) => {
    await startTransaction(team)
      .then((res) => {
        if (res) {
          const pgValue = new URL(res.url).searchParams.get('pg');
          if (isEqual('rthanks', pgValue)) {
            return (window.location.href = `${res.url}`);
          }
          return res.text();
        }
      })
      .then((data) => {
        const urlFromString = new DOMParser()
          .parseFromString(data, 'text/html')
          .querySelector('form');
        const newUrl = urlFromString.action.split('/');
        const uniqId = newUrl[newUrl.length - 1];
        setTransactionId(uniqId);
        return postStep(uniqId, {
          pg: 'tfind',
          fr_id,
          fr_tjoin: `${teamId}`,
          skip_login_page: 'false',
        });
      })
      .then((response) => {
        setJoinTeamId(teamId);
        redirect(`/${ROUTE_KEYS.PARTICIPATION}`, {
          pagename,
          fr_id,
          fr_tjoin: `${teamId}`,
          teamId,
        });
      });
  };

  return { joinTeamFromExternalLink };
};

export const usePayment = () => {
  const [creditErrorMessage, setCreditErrorMessage] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    queryString: { fr_id },
  } = useQueryString();
  const {
    user: { firstName, lastName, email },
  } = useAuth();
  const {
    data: { personalFormData, transactionId },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();
  const { total } = useRegTotalToPay();
  const trConfigResponse = useQuery(
    `${LUMINATE_API_SECTIONS.CRTEAMRAISERAPI}/getTeamraiserConfig`,
    {
      fr_id,
    }
  );

  const checkoutOptions =
    trConfigResponse?.queryResponse?.data?.teamraiserConfig?.checkoutOptions;

  const handlePayment = async (paymentFormData = {}) => {
    const config = getCheckoutConfig(checkoutOptions);
    const checkoutInstance = getCheckoutInstance(config);
    const newFirstName = paymentFormData?.billingFirstName || firstName;
    const newLastName = paymentFormData?.billingLastName || lastName;
    const fullName = `${newFirstName} ${newLastName}`;
    const postalCode = paymentFormData?.zip || personalFormData?.zip;
    const countryName = paymentFormData?.country || personalFormData?.country;

    checkoutInstance.checkoutModal.open({
      // Amount must match with the total
      baseAmount: Math.round(Number(total) * 100),
      billingDetails: {
        name: fullName,
        email,
        address: {
          postalCode,
          country: getCountryCode(
            checkoutOptions?.countryCodeNamePairs || '{}',
            countryName
          ),
        },
      },
    });

    return checkoutInstance.checkoutComplete.subscribe(async (resT) => {
      setIsLoading(true);
      await postStep(transactionId, {
        pg: 'paymentForm',
        fr_id,
        responsive_payment_typepay_typeradiosubmit: 'true',
        responsive_payment_typesubmit: 'true',
        billing_first_namename: newFirstName,
        billing_first_namesubmit: 'true',
        billing_last_namename: newLastName,
        billing_last_namesubmit: 'true',
        billing_suffix: '',
        billing_suffixsubmit: 'true',
        billing_addr_street1name:
          paymentFormData.address1 || personalFormData.address1,
        billing_addr_street1submit: 'true',
        billing_addr_street2name: '',
        billing_addr_street2submit: 'true',
        billing_addr_cityname: paymentFormData.city || personalFormData.city,
        billing_addr_citysubmit: 'true',
        billing_addr_state: paymentFormData.state || personalFormData.state,
        billing_addr_statesubmit: 'true',
        billing_addr_zipname: postalCode,
        billing_addr_zipsubmit: 'true',
        billing_addr_country: countryName,
        billing_addr_countrysubmit: 'true',
        btn_next: 'Process Payment',
        environment_id: config.environmentId,
        merchant_account_configuration_id: config.paymentConfigurationId,
        user_donation_amt: `$${total}`,
        is_request_from_pco_validation: true,
        transactionId: resT.transaction.id,
      }).then((res) => {
        setIsLoading(false);
        /** This is to get the error message for credit card */
        const formElement = new DOMParser()
          .parseFromString(res, 'text/html')
          .querySelector('.field-error-text');
        const errorMessage = formElement && formElement.textContent;
        setCreditErrorMessage(errorMessage);
      });
    });
  };

  return { creditErrorMessage, handlePayment, isLoading };
};
