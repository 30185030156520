import React from 'react';
import Cropper from 'react-cropper';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Achievements,
  Button,
  FundraisingProgressBar,
  Hero,
  Icon,
  OverflowText,
  PageTitle,
  SocialShare,
} from '@frx/frx-components';
import { isEmpty } from 'lodash';
import currency from 'currency.js';
import {
  DonationArray,
  EditModal,
  ErrorMessage,
  Figure,
  InputField,
} from '../../../components';
import {
  capitalize,
  trimZeroDecimals,
  useConfiguration,
  usePersonalPageContext,
  useQueryString,
} from '../../../utils';
import {
  QUILL_MODULES,
  MARATHON_HOME_PAGE_URL,
  FR_ID_SJ_VOLUNTEER_QA,
  FR_ID_SJ_VOLUNTEER_PROD,
} from '../../../app.constants';
import { getHeroImage } from '../../../assets';
import { colors } from '../../../styles';
import 'cropperjs/dist/cropper.css';
import 'react-quill/dist/quill.snow.css';
import styles from './Personal.module.scss';

const renderError = ({ updateError }) =>
  updateError && <ErrorMessage {...{ errorText: updateError }} />;

export const Personal = () => {
  /** hooks */
  const [isOtherAmountInvalid, setIsOtherAmountInvalid] = React.useState(false);
  const [image, setImage] = React.useState();
  const [cropper, setCropper] = React.useState();
  const [richText, setRichText] = React.useState();
  const [kickStartAmount, setKickStartAmount] = React.useState();
  const [donationFormLink, setDonationFormLink] = React.useState();
  const goalRef = React.useRef();
  const otherKickStartAmountRef = React.useRef();
  const { t } = useTranslation(['options']);
  /** data */
  const {
    queryString: { reg_type, fr_id },
  } = useQueryString();
  const {
    commands: {
      handleChangeHeroImageIndex,
      handleGoalCancel,
      handleGoalModal,
      handleHeroImageModalShow,
      handleImageCancel,
      handleImageModal,
      handleRichTextCancel,
      handleRichTextModal,
      handleSetHeroImageModal,
      handleUpdateGoal,
      handleUpdateImage,
      handleUpdateRichText,
      setHeroImageIndex,
    },
    data: {
      achievements,
      donationArrayData,
      editGoalModal,
      editHeroImageModal,
      editImageModal,
      editRichTextModal,
      eventDetails,
      heroImageBackground,
      heroImageIndex,
      heroImagesArr,
      ownPage,
      participant,
      participantProgress,
      personalPageInfo,
      profileImage,
      topDonors,
      updateError,
      eventInfo,
      donationLink,
    },
  } = usePersonalPageContext();
  const configuration = useConfiguration();
  const {
    user: { firstName: userFirstName, lastName: userLastName },
  } = configuration.settings.luminate.properties;

  React.useEffect(() => {
    const defaultAmount =
      donationArrayData &&
      donationArrayData.donationArray &&
      donationArrayData.donationArray.filter((d) => d.name === 'Level 3');
    if (defaultAmount && defaultAmount.length) {
      setKickStartAmount(defaultAmount[0].value);
      setDonationFormLink(defaultAmount[0].link);
    }
    setDonationFormLink(donationLink);
    setImage(profileImage.customUrl);
    setRichText(personalPageInfo.richText);
  }, [donationArrayData, profileImage, personalPageInfo, donationLink]);

  React.useEffect(() => {
    const heroImagePreselectionIndex =
      Number(heroImageBackground?.charAt(heroImageBackground.length - 1)) || 1;
    setHeroImageIndex(heroImagePreselectionIndex);
  }, [heroImageBackground, setHeroImageIndex]);

  const firstName = capitalize(participant.name.first) || userFirstName;
  const lastName = capitalize(participant.name.last) || userLastName;
  const screenName = participant.name.screenname || `${firstName} ${lastName}`;

  const teamEventDetails = {
    text: participant.eventName,
    url: MARATHON_HOME_PAGE_URL,
  };

  const isRegTypeHeroes = () => {
    if (reg_type === 'heroes') {
      return true;
    }
    return false;
  };

  const teamNameDetails = {
    text: participant.teamName,
    url: isRegTypeHeroes(reg_type)
      ? `${participant.teamPageUrl}&reg_type=${reg_type}`
      : `${participant.teamPageUrl}`,
  };

  /** functions */
  const handleCloseArray = () => {
    setIsOtherAmountInvalid(false);
    setKickStartAmount();
  };
  const handleSelectAmount = (e) => {
    const selected = String(e.currentTarget.innerText).trim();
    if (String(kickStartAmount).trim() === selected) {
      setKickStartAmount();
    } else {
      setKickStartAmount(selected);
      setDonationFormLink(
        donationArrayData.donationArray.filter(
          (d) => String(d.value).trim() === selected
        )[0].link
      );
    }
  };
  const handleDonateClick = () => {
    location.href = donationFormLink;
  };

  const handleChange = (e) => {
    const [file] = e.files;
    if (file) {
      const reader = new FileReader();
      reader.onload = (ev) => setImage(ev.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleProfileUpdate = () => {
    cropper
      .getCroppedCanvas({ maxHeight: 300, maxWidth: 300 })
      .toBlob((blob) => {
        handleUpdateImage(blob);
      }, 'image/jpeg');
  };

  const handleGoalUpdate = () => handleUpdateGoal(goalRef.current.value * 100);

  const handleRichTextChange = (html) => setRichText(html);

  const handleStoryUpdate = () => handleUpdateRichText(richText);

  const handleProfileCancel = () => {
    handleImageCancel();
    setImage(profileImage.customUrl);
  };

  const handleRichTextModalCancel = () => {
    handleRichTextCancel();
    setRichText(personalPageInfo.richText);
  };

  const isVolunteerEvent =
    parseInt(fr_id) === FR_ID_SJ_VOLUNTEER_QA ||
    parseInt(fr_id) === FR_ID_SJ_VOLUNTEER_PROD;

  return (
    <main className='personal-page'>
      <Hero
        className={styles.heroComponent}
        background={getHeroImage(heroImageBackground, isVolunteerEvent)}
        setHeight='50rem'
        backgroundPosition='bottom'
      >
        <div className='container'>
          {ownPage && (
            <EditModal
              className={styles.editHeroImageBttn}
              handleModal={handleHeroImageModalShow}
              onPrimaryAction={() =>
                handleSetHeroImageModal(`hero-image-${heroImageIndex}`)
              }
              onSecondaryAction={handleHeroImageModalShow}
              title='Photo'
              visible={editHeroImageModal}
            >
              {renderError({ updateError })}
              <h1>
                Choose your favorite background image. This will display in your
                fundraising page.
              </h1>
              <div className={cn('mt-4', styles.thumbnailContainer)}>
                <div className='columns is-multiline'>
                  {heroImagesArr.map((imageSrc, idx) => {
                    const index = idx + 1;
                    const isSelected = heroImageIndex === index;
                    return (
                      <div key={index} className='column is-half'>
                        <Button
                          className={cn(
                            styles.thumbnailBttn,
                            isSelected && styles.selected
                          )}
                          onClick={() => handleChangeHeroImageIndex(index)}
                        >
                          <Figure alt={`Thumnail-${index}`} src={imageSrc} />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </EditModal>
          )}
        </div>
      </Hero>
      <section className={cn('section', styles.section)}>
        <div className='container'>
          <div className={cn(styles.pageColumns, 'columns')}>
            {/* left column */}
            <div
              className={cn(
                styles.sideColumn,
                'column',
                'is-one-quarter-desktop'
              )}
            >
              <div className={cn('box', styles.box)}>
                <div
                  className={cn(
                    styles.componentContainer,
                    styles.profileImageContainer,
                    'is-hidden-mobile'
                  )}
                >
                  <Figure alt='profile' src={profileImage.customUrl} />
                  {ownPage && (
                    <EditModal
                      className={styles.editProfile}
                      handleModal={handleImageModal}
                      onPrimaryAction={handleProfileUpdate}
                      onSecondaryAction={handleProfileCancel}
                      title='Photo'
                      visible={editImageModal}
                    >
                      {renderError({ updateError })}
                      <InputField
                        accept='image/*'
                        label='Select an image to upload'
                        onChange={handleChange}
                        type='file'
                      />
                      <Cropper
                        autoCropArea={1}
                        background={false}
                        checkOrientation={false}
                        guides
                        initialAspectRatio={1}
                        minCanvasHeight={300}
                        minCanvasWidth={300}
                        minContainerHeight={300}
                        minContainerWidth={300}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        onInitialized={(instance) => setCropper(instance)}
                        responsive
                        src={image}
                        viewMode={1}
                      />
                    </EditModal>
                  )}
                </div>
                <div
                  className={cn(
                    styles.componentContainer,
                    styles.eventDetailsContainer
                  )}
                >
                  <h2>Event Details</h2>
                  <div className={styles.detailItem}>
                    <Icon
                      color={colors.secondary.swatch7}
                      name='calendar'
                      size={28}
                    />
                    <span>{eventDetails.eventDate}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <Icon
                      color={colors.secondary.swatch7}
                      name='map-filled'
                      size={28}
                    />
                    <div>
                      {eventDetails.eventAddress.split('\n').map((a) => (
                        <span key={a}>{String(a).trim()}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <hr />
                <div className={styles.componentContainer}>
                  <div data-testid='personalAchievements'>
                    {!isEmpty(achievements) && (
                      <Achievements
                        firstName={firstName}
                        achievements={achievements}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    styles.componentContainer,
                    styles.donorsContainer
                  )}
                >
                  <h2>{`${firstName || 'Test'}'s Donors:`}</h2>
                  <ul>
                    {(topDonors || []).map(({ donorName, donorAmount }) => (
                      <li key={`${donorName}-${donorAmount}`}>
                        <p>{donorName}</p>
                        <p>{donorAmount}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* right column */}
            <div
              className={cn(
                styles.mainColumn,
                'column',
                'is-three-quarter-desktop'
              )}
            >
              <div
                className={cn(
                  styles.componentContainer,
                  styles.profileImageContainer,
                  'is-hidden-tablet'
                )}
              >
                <Figure alt='profile' src={profileImage.customUrl} />
                {ownPage && (
                  <EditModal
                    className={styles.editProfile}
                    handleModal={handleImageModal}
                    onPrimaryAction={handleProfileUpdate}
                    onSecondaryAction={handleProfileCancel}
                    title='Profile'
                    visible={editImageModal}
                  >
                    {renderError({ updateError })}
                    <InputField
                      accept='image/*'
                      label='Select an image to upload'
                      onChange={handleChange}
                      type='file'
                    />
                    <Cropper
                      autoCropArea={1}
                      background={false}
                      checkOrientation={false}
                      guides
                      initialAspectRatio={1}
                      minCanvasHeight={300}
                      minCanvasWidth={300}
                      minContainerHeight={300}
                      minContainerWidth={300}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      onInitialized={(instance) => setCropper(instance)}
                      responsive
                      src={image}
                      viewMode={1}
                    />
                  </EditModal>
                )}
              </div>
              <div
                className={cn(styles.componentContainer, styles.titleContainer)}
              >
                <div>
                  {participant && (
                    <PageTitle
                      title={screenName}
                      firstSubtitle={teamEventDetails}
                      secondSubtitle={teamNameDetails}
                    />
                  )}
                </div>
                <SocialShare
                  description='Help support the kids of St. Jude'
                  title='Share'
                />
              </div>
              {participantProgress && (
                <>
                  <div
                    className={cn(
                      styles.componentContainer,
                      styles.progressContainer
                    )}
                  >
                    <FundraisingProgressBar
                      goal={trimZeroDecimals(
                        currency(participantProgress.goal, {
                          fromCents: true,
                        }).format()
                      )}
                      percentage={participantProgress.percent}
                      amountRaised={trimZeroDecimals(
                        currency(participantProgress?.raised, {
                          fromCents: true,
                        }).format()
                      )}
                    >
                      {ownPage && (
                        <EditModal
                          className={styles.editGoal}
                          handleModal={handleGoalModal}
                          onPrimaryAction={handleGoalUpdate}
                          onSecondaryAction={handleGoalCancel}
                          title='Goal'
                          visible={editGoalModal}
                        >
                          {renderError({ updateError })}
                          <InputField
                            defaultValue={currency(participantProgress.goal, {
                              fromCents: true,
                            })}
                            errorText={() => 'Enter valid number'}
                            iconProps={{
                              name: 'dollar-sign',
                              placement: 'left',
                            }}
                            label='Goal'
                            max={999999999}
                            minLength={1}
                            ref={goalRef}
                            required
                            type='number'
                          />
                        </EditModal>
                      )}
                    </FundraisingProgressBar>
                  </div>
                </>
              )}
              {eventInfo?.accepting_donations === 'true' && (
                <div
                  className={cn(
                    styles.componentContainer,
                    styles.donationArrayContainer
                  )}
                >
                  {participant && (
                    <div className={styles.donateButtonContainer}>
                      <Button
                        variant='primary'
                        buttonColor='red'
                        className={styles.donateButton}
                        onClick={handleDonateClick}
                      >
                        {t('donate')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <hr className='line double is-hidden-touch' />
              <div
                className={cn(styles.componentContainer, styles.storyContainer)}
              >
                {ownPage && (
                  <EditModal
                    className={styles.editStory}
                    handleModal={handleRichTextModal}
                    onPrimaryAction={handleStoryUpdate}
                    onSecondaryAction={handleRichTextModalCancel}
                    title='Story'
                    visible={editRichTextModal}
                  >
                    {renderError({ updateError })}
                    <ReactQuill
                      id='richText'
                      modules={QUILL_MODULES}
                      name='richText'
                      onChange={handleRichTextChange}
                      theme='snow'
                      value={richText}
                    />
                  </EditModal>
                )}
                {personalPageInfo && <OverflowText content={richText} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
