import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputField, Screen, StepHeader } from '../../components';
import { AddressField } from '../personal/questions';
import {
  isEmpty,
  useAuth,
  usePayment,
  useQueryString,
  useRegistrationDataProvider,
  useRegTotalToPay,
} from '../../utils';
import { THANK_YOU_SURVEY_BASE_URL } from '../../app.constants';

import styles from './Payment.module.scss';

export const Payment = ({ showSidebar }) => {
  const [addressEdit, setAddressEdit] = React.useState(false);
  const [, setHasFormErrors] = React.useState(true);
  const { t } = useTranslation(['payment_screen']);

  const {
    queryString: { team },
  } = useQueryString();
  const {
    user: { firstName, lastName },
  } = useAuth();
  const {
    commands: { setPaymentFormData },
    data: {
      ageConfig,
      paymentFormData,
      personalFormData,
      selectedParticipationType,
    },
  } = useRegistrationDataProvider();
  const { total } = useRegTotalToPay();
  const { handlePayment, isLoading } = usePayment();

  const handleOnEditOrCancel = () => setAddressEdit((prevState) => !prevState);
  const handleOnFormChange = (fields, hasErrors) => {
    setPaymentFormData(fields);
    setHasFormErrors(hasErrors);
  };
  const handlePaymentOnSubmit = () => handlePayment(paymentFormData);

  const checkEmptyPaymentForm = (address) => {
    if (isEmpty(address)) return null;
    for (const key of Object.keys(address)) {
      if (isEmpty(address[key])) {
        address[key] = personalFormData[key];
      }
    }
    return address;
  };

  let billingInfo;

  if (personalFormData) {
    if (addressEdit) {
      billingInfo = (
        <>
          <div className={styles.billingName}>
            <InputField
              name='billingFirstName'
              label={t('payment_screen:first_name_label_text')}
              defaultValue={paymentFormData?.billingFirstName || firstName}
              required
            />
            <InputField
              name='billingLastName'
              label={t('payment_screen:last_name_label_text')}
              defaultValue={paymentFormData?.billingLastName || lastName}
              required
            />
          </div>
          <AddressField
            formData={
              checkEmptyPaymentForm(paymentFormData) ?? personalFormData
            }
          />
        </>
      );
    } else {
      billingInfo = (
        <ul>
          <li>
            {paymentFormData?.billingFirstName || firstName}{' '}
            {paymentFormData?.billingLastName || lastName}
          </li>
          <li>{paymentFormData?.address1 || personalFormData?.address1}</li>
          <li>{paymentFormData?.city || personalFormData.city}</li>
          <li>{paymentFormData?.state || personalFormData.state}</li>
          <li>{paymentFormData?.zip || personalFormData.zip}</li>
        </ul>
      );
    }
  }

  // build TY Survey Link
  const THANK_YOU_SURVEY_URL = `${THANK_YOU_SURVEY_BASE_URL}RegType=sjmmw&RaceType=${encodeURIComponent(
    selectedParticipationType?.distance
  )}&FundRaiseType=${encodeURIComponent(
    selectedParticipationType?.commitment
  )}&RegLevel=${team}&Fname=${encodeURIComponent(
    firstName
  )}&Lname=${encodeURIComponent(lastName)}&Age=${encodeURIComponent(
    ageConfig?.ageOnRaceDay
  )}&Gender=${encodeURIComponent(
    personalFormData?.gender
  )}&City=${encodeURIComponent(
    personalFormData?.city
  )}&State=${encodeURIComponent(
    personalFormData?.state
  )}&Zip=${encodeURIComponent(
    personalFormData?.zip
  )}&Phone=${encodeURIComponent(
    personalFormData?.homePhone.replace(/\s+/g, '')
  )}&TimesParticipated=${encodeURIComponent(
    personalFormData?.timesParticipatedSjmmw
  )}&Boston=${encodeURIComponent(
    personalFormData?.qkeyBoston
  )}&SJPatientFamily=${encodeURIComponent(personalFormData?.isPatientFam)}`;

  // save and/or updates the survey link inside session storage
  window.localStorage.setItem('THANK_YOU_SURVEY_URL', THANK_YOU_SURVEY_URL);

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <section>
        <h3>Total:&emsp;${total}</h3>
        <Form onChange={handleOnFormChange} onSubmit={handlePaymentOnSubmit}>
          <div className={styles.billingInfo}>
            <h3>{t('payment_screen:billing_information_text')}</h3>
            {billingInfo}
            <Button
              className={styles.edit}
              onClick={handleOnEditOrCancel}
              variant='text'
            >
              {!addressEdit && t('payment_screen:edit_button_text')}
            </Button>
          </div>
          <Button
            className={styles.action}
            disabled={isLoading}
            isfullWidth
            isLoading={isLoading}
            type='submit'
            variant='primary'
          >
            {t('payment_screen:pay_button_text')} ${total}
          </Button>
        </Form>
      </section>
    </Screen>
  );
};

Payment.propTypes = {
  showSidebar: bool,
};
