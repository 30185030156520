import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/* import DayJS from 'dayjs'; */
import cn from 'classnames';
import { orderBy } from 'lodash';
import {
  Button,
  colors,
  convertCentsToDollars,
  FundraisingProgressBar,
  Hero,
  Modal,
  QueryLoading,
  SocialShare,
  Tab,
  TabList,
  Tabs,
} from '@frx/frx-components';
import {
  convertCurrencyToNumber,
  encodeAsQueryString,
  getCompanyIcon,
  getCompanyName,
  isEqual,
  useConfiguration,
  useEventPageProvider,
  useQueryString,
  useRegistrationDataProvider,
  useJoinTeam,
  getDonationUrl,
  formatEventDate,
} from '../../../utils';
import { Label, TopParticipants, TopTeams } from '../../../components';
import {
  heroMarathonGreetingPage,
  iconGiving,
  iconProfile,
  iconRunning,
} from '../../../assets';
import { ROUTE_KEYS } from '../../../app.constants';
import { SelectField } from '../../../components/forms';

import styles from './Event.module.scss';

export const Event = () => {
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [activeInnerTab, setActiveInnerTab] = useState(1);

  const configuration = useConfiguration();
  const {
    buildPath,
    buildCleanPath,
    queryString: { fr_id, reg_type },
  } = useQueryString();
  const { t } = useTranslation(['entry']);
  const {
    data: { companyList },
  } = useRegistrationDataProvider();
  const {
    appSearchCriteria: { pagename },
    luminate,
    eventScheduleLink,
  } = configuration.settings;
  const { properties } = luminate;
  const {
    formId,
    user: { consId, registered },
    entryPage: { amountRaised, fundraisingGoal },
  } = properties;
  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();
  const {
    commands: { setCompanyId },
    data: { teamsByCompany, topParticipants, topTeamsWithMembers },
  } = useEventPageProvider();
  const { joinTeamFromExternalLink } = useJoinTeam();
  /* const eventDate = DayJS(eventInfo?.event_date); */
  const isAcceptingDonations = eventInfo?.accepting_donations === 'true';
  const isAcceptingRegistrations =
    eventInfo?.accepting_registrations === 'true';
  const { swatch1 } = colors.primary;
  const { grey10 } = colors.monochromatic;

  const companies = React.useMemo(() => {
    const newCompanies = (companyList || []).map((cl, index) => ({
      ...cl,
      icon: getCompanyIcon(cl.companyName),
      /* label: cl.companyName, */
      label: getCompanyName(cl.companyName),
      // This for tabs to find the proper id
      value: index + 3,
    }));
    return orderBy(newCompanies, ['companyId']);
  }, [companyList]);

  const handleJoinTeam = (teamId, teamCaptain) => {
    // leaderboards join a team
    const isCaptain = isEqual(teamCaptain, consId);
    if (isCaptain) {
      location.href = `TRC?pg=center&fr_id=${fr_id}&reg_type=${reg_type}`;
    } else {
      joinTeamFromExternalLink('join-team', teamId, fr_id, pagename);
    }
  };
  const handleShowModal = (modal) => {
    if (modal === 'location')
      setIsLocationModalVisible((prevState) => !prevState);
    if (modal === 'schedule')
      setIsScheduleModalVisible((prevState) => !prevState);
  };
  const handleInnerTab = (tabIndex, companyId) => () => {
    setActiveInnerTab(tabIndex);
    if (companyId) {
      setCompanyId(companyId);
    }
  };

  const dropdownOptions = [
    { label: 'Individuals', value: 1 },
    { label: 'Teams', value: 2 },
    ...companies,
  ];
  const handleOnChangeSelection = (e) => {
    const tabIndex = Number(e.value);
    setActiveInnerTab(tabIndex);
    const company = companies.find(({ value }) => isEqual(value, tabIndex));
    if (company) {
      setCompanyId(company.companyId);
    }
  };

  const DropdownSelection = () => (
    <SelectField
      className='is-hidden-desktop'
      value={activeInnerTab}
      label='Displaying'
      onChange={handleOnChangeSelection}
      options={dropdownOptions}
    />
  );

  const raisedAmount = convertCurrencyToNumber(amountRaised);
  const goalFundraising = convertCurrencyToNumber(fundraisingGoal);

  const donateUrl = `${luminate.url}/Donation2?df_id=${formId}&${formId}.donation=form1&PROXY_ID=${fr_id}&PROXY_TYPE=21&FR_ID=${fr_id}`;

  return (
    <main className='event-page'>
      <Hero
        background={heroMarathonGreetingPage}
        backgroundPosition='bottom'
        setHeight='35rem'
      >
        <div className={cn('container', styles.heroContainer)}>
          <div className='hero-body'>
            <div className='level'>
              <div className={cn('level-left', styles.levelLeft)}>
                <div className={cn('level-item', styles.flexColumns)}>
                  <h1
                    className={cn(
                      'title',
                      'is-spaced',
                      'is-size-1',
                      styles.heroTitle
                    )}
                  >
                    {t('hero.event_name')}
                  </h1>
                  <h3
                    className={cn(
                      'subtitle',
                      'is-size-2',
                      'mb-5',
                      styles.heroSubtitle
                    )}
                  >
                    {/* {eventDate.format('MMMM D, YYYY')} */}
                    {/* hack to get event date in spanish until dayjs will cooperate */}
                    {formatEventDate(eventInfo.event_date)}
                  </h3>
                  <ul
                    className={cn(
                      styles.componentContainer,
                      styles.eventInfoLinks
                    )}
                  >
                    <li>
                      <span>{t('hero.location')}</span>
                      <Button
                        title='Location'
                        variant='tertiary'
                        buttonColor='white'
                        className={styles.heroIcon}
                        onClick={() => handleShowModal('location')}
                        iconProps={{
                          name: 'map-filled',
                          color: grey10,
                          size: '26',
                        }}
                      />
                    </li>
                    <Modal
                      onClosePress={() => handleShowModal('location')}
                      title={t('hero.event_location')}
                      visible={isLocationModalVisible}
                    >
                      <div className='content'>
                        <p className='has-text-black'>
                          {t('hero.event_name')}
                          <br />
                          {formatEventDate(eventInfo.event_date)}
                          <br />
                          <strong>
                            {eventInfo?.city}, {eventInfo?.state}
                          </strong>
                        </p>
                      </div>
                    </Modal>
                    <li>
                      <span>{t('hero.schedule')}</span>
                      <Button
                        title='schedule'
                        variant='tertiary'
                        buttonColor='white'
                        className={styles.heroIcon}
                        onClick={() => handleShowModal('schedule')}
                        iconProps={{
                          name: 'calendar',
                          color: grey10,
                          size: '26',
                        }}
                      />
                    </li>
                    <Modal
                      onClosePress={() => handleShowModal('schedule')}
                      title={t('hero.event_schedule')}
                      visible={isScheduleModalVisible}
                    >
                      <div className='content'>
                        <h3>{t('hero.event_name')}</h3>
                        <p className='has-text-black'>
                          <strong>{t('hero.event_date')}</strong>&nbsp;
                          {/* {eventDate.format('dddd, MMMM D, YYYY')} */}
                          {/* hack to get event date in spanish until dayjs will cooperate */}
                          {formatEventDate(eventInfo.event_date, true)}
                          <br />
                          <a
                            href={eventScheduleLink}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <strong>{t('hero.event_schedule')}</strong>
                          </a>
                          <br />
                        </p>
                      </div>
                    </Modal>
                    <li>
                      <span>{t('hero.contact')}</span>
                      <Link
                        to={() =>
                          buildCleanPath(`/${ROUTE_KEYS.CONTACT}`, {
                            pagename,
                            fr_id,
                            reg_type,
                          })
                        }
                      >
                        <Button
                          title='Contact'
                          variant='tertiary'
                          buttonColor='white'
                          className={styles.heroIcon}
                          iconProps={{
                            name: 'envelope',
                            color: grey10,
                            size: '26',
                          }}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='level-right'>
                <div
                  className={cn(
                    'level-item',
                    'has-text-right',
                    styles.flexColumns
                  )}
                >
                  <div className={cn('buttons', styles.buttonGroup)}>
                    {consId && registered
                      ? (isAcceptingRegistrations || isAcceptingDonations) && (
                          <Button
                            buttonColor='blue'
                            variant='primary'
                            onClick={() =>
                              (location.href = `TRC?pg=center&fr_id=${fr_id}&reg_type=${reg_type}`)
                            }
                            isfullWidth
                          >
                            {t('hero.fundraising_center')}
                          </Button>
                        )
                      : isAcceptingRegistrations && (
                          <Link
                            to={() =>
                              buildCleanPath(`/${ROUTE_KEYS.PARTICIPATION}`, {
                                pagename,
                                fr_id,
                                reg_type,
                              })
                            }
                          >
                            <Button
                              buttonColor='blue'
                              isfullWidth
                              variant='primary'
                            >
                              {t('hero.register')}
                            </Button>
                          </Link>
                        )}
                    {isAcceptingDonations && (
                      <Button
                        variant='primary'
                        buttonColor='red'
                        onClick={() => {
                          location.href = getDonationUrl(donateUrl);
                        }}
                      >
                        {t('hero.donate')}
                      </Button>
                    )}
                  </div>
                  <div
                    className={cn(
                      styles.componentContainer,
                      styles.socialShare,
                      'has-text-light'
                    )}
                  >
                    <SocialShare
                      description='Help support the kids of St. Jude'
                      title='Share'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <div className={styles.stripes} />
      <section className={cn('section', styles.section)}>
        <div className='container'>
          <div
            className={cn(styles.componentContainer, styles.progressContainer)}
          >
            <FundraisingProgressBar
              goal={convertCentsToDollars(goalFundraising, {
                divider: false,
                formatted: true,
                trimZeros: true,
              })}
              percentage={Math.min(
                Math.floor(
                  goalFundraising !== 0
                    ? (Number(raisedAmount) / Number(goalFundraising)) * 100
                    : 100
                ),
                100
              )}
              amountRaised={convertCentsToDollars(raisedAmount, {
                divider: false,
                formatted: true,
                trimZeros: true,
                rounded: true,
              })}
            />
          </div>
          <hr className='line mb-6' />
          <div
            className={cn(styles.componentContainer, styles.eventContent)}
            dangerouslySetInnerHTML={{
              __html: t('intro.intro').replace(
                '{eventDate}',
                formatEventDate(eventInfo.event_date)
              ),
            }}
          />
          <div
            className={cn(
              'box',
              styles.box,
              styles.componentContainer,
              styles.tabContainer
            )}
          >
            <div className={styles.tabPanelContainer}>
              <Label
                className={styles.tableHeader}
                text={t('leaderboards.fundraising_leaderboards')}
              />
              <div className={cn(styles.componentContainer)}>
                <Tabs activeTab={activeInnerTab} orientation='vertical'>
                  <TabList className='is-hidden-touch'>
                    <Tab
                      iconProps={{
                        name: 'walking',
                        color: swatch1,
                        viewBox: '0 0 64 64',
                        size: 40,
                        noTransform: true,
                      }}
                      id={1}
                      label={t('leaderboards.individual')}
                      onClick={handleInnerTab(1)}
                    />
                    <Tab
                      iconProps={{
                        name: 'business-man',
                        color: swatch1,
                        viewBox: '0 0 64 64',
                        size: 40,
                        noTransform: true,
                      }}
                      label={t('leaderboards.teams')}
                      id={2}
                      onClick={handleInnerTab(2)}
                    />
                    {(companies || []).map((c) => (
                      <Tab
                        iconProps={{
                          ...c.icon,
                          color: swatch1,
                          size: 40,
                          noTransform: true,
                        }}
                        id={c.value}
                        key={c.companyId}
                        /* label={c.companyName} */
                        label={getCompanyName(c.companyName)}
                        onClick={handleInnerTab(c.value, c.companyId)}
                        variant='secondary'
                      />
                    ))}
                  </TabList>
                  <div className={styles.participantPanel}>
                    <TopParticipants
                      DropdownSelection={DropdownSelection}
                      id={1}
                      participants={topParticipants}
                      acceptingDonationsVisible={isAcceptingDonations}
                    />
                    <TopTeams
                      DropdownSelection={DropdownSelection}
                      id={2}
                      teams={topTeamsWithMembers}
                      title={t('leaderboards.top_teams')}
                      acceptingRegistrationsVisible={isAcceptingRegistrations}
                      {...{
                        handleJoinTeam,
                      }}
                    />
                    {teamsByCompany.isLoading ? (
                      <QueryLoading />
                    ) : (
                      <>
                        {(companies || []).map((c) => (
                          <TopTeams
                            key={`${c.companyId} - ${c.value}`}
                            DropdownSelection={DropdownSelection}
                            id={c.value}
                            teams={teamsByCompany.data?.team}
                            /* title={c.companyName} */
                            title={getCompanyName(c.companyName)}
                            acceptingRegistrationsVisible={
                              isAcceptingRegistrations
                            }
                            {...{
                              handleJoinTeam,
                            }}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={cn('section', styles.section, styles.hasSolidBg)}>
        <div className='container'>
          <div className='content has-text-centered'>
            <div className='mb-6'>
              <h2>{t('cta.header.header')}</h2>
              <p>
                {t('cta.header.subtitle_1')}
                <sup>&reg;</sup>
                {t('cta.header.subtitle_2')}
              </p>
            </div>
            <div className='columns'>
              <div className='column p-6'>
                <figure className='is-square mb-0'>
                  <img src={iconProfile} alt='Fundraise' />
                </figure>
                <h3>{t('cta.step_1.header')}</h3>
                <p>
                  {t('cta.step_1.subtitle_1')}
                  {consId &&
                  registered &&
                  (isAcceptingRegistrations || isAcceptingDonations) ? (
                    <a
                      href={`/TRC?${encodeAsQueryString({
                        fr_id,
                        pg: 'center',
                      })}`}
                    >
                      {t('cta.step_1.visit_center')}
                    </a>
                  ) : (
                    isAcceptingRegistrations && (
                      <Link
                        to={(location) =>
                          buildPath(`/${ROUTE_KEYS.LOGIN}`, {
                            redirect: location.pathname,
                          })
                        }
                      >
                        {t('cta.step_1.log_in')}
                      </Link>
                    )
                  )}
                </p>
              </div>
              <div className='column p-6'>
                <figure className='is-square mb-0'>
                  <img src={iconRunning} alt='Donate' />
                </figure>
                <h3>{t('cta.step_2.header')}</h3>
                {isAcceptingDonations && (
                  <p>
                    {t('cta.step_2.subtitle_1')}
                    <sup>&reg;</sup>
                    {t('cta.step_2.subtitle_2')}
                    <Link
                      to={() =>
                        buildCleanPath(`/${ROUTE_KEYS.SEARCH}`, {
                          pagename,
                          fr_id,
                        })
                      }
                    >
                      {t('cta.step_2.search_name')}
                    </Link>
                  </p>
                )}
              </div>
              <div className='column p-6'>
                <figure className='is-square mb-0'>
                  <img src={iconGiving} alt='Give' />
                </figure>
                <h3>{t('cta.step_3.header')}</h3>
                {isAcceptingDonations && (
                  <p>
                    <a href={getDonationUrl(eventInfo?.donate_event_url)}>
                      {t('cta.step_3.subtitle_1')}
                    </a>
                    {t('cta.step_3.subtitle_2')}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
